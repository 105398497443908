<template>
  <div class="item_line_datetext_cell_container">
    <div class="item_line_text-wrapper">
      {{ dateText }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { JSONPath } from "jsonpath-plus";
import type { ConfigColumn } from "~/@types/localTypes/table";

const props = defineProps<{
  item: any;
  column: ConfigColumn;
}>();

const dateText = computed(() => {
  const { formatDate } = useDateFormat();

  const { column, item } = props;
  const path = column.path;
  if (isNotDefined(path)) return "";

  const value = JSONPath({ path, json: item })[0];
  const verb = column.dateText ?? "Déposée";
  const dateFormat = column.dateFormat ?? "DD-MM-YYYY";

  if (isValidDate(value)) {
    const date = formatDate(value, dateFormat);
    const displayHours = props.column.displayHours ?? false;

    const timeString = displayHours
      ? ` à ${formatDate(value, `${EDateFormat.HOUR}:${EDateFormat.MINUTE}`)}`
      : "";

    return `${verb} le ${date}${timeString}`;
  }
  return `${verb} à une date inconnue`;
});

function isValidDate(date: string): boolean {
  return !Number.isNaN(Date.parse(date));
}
</script>

<style lang="scss" scoped>
.item_line_datetext_cell_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  font-size: 16px;
  line-height: 20px;
  color: $uds-neutral-800;

  .item_line_text-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>

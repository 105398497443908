<template>
  <div class="squircle-text-cell-container">
    <div class="st-icon-wrapper">
      <uds-squircle-icon :icon="iconName" :color="iconColor" size="small" />
    </div>
    <div class="st-text-wrapper">
      <CommonsTextTooltipIfTextOverflowed :text="text" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { JSONPath } from "jsonpath-plus";
import type { ConfigColumn } from "~/@types/localTypes/table";
const props = defineProps<{
  item: any;
  column: ConfigColumn;
}>();

const text = computed(() => {
  const path = props.column.path;
  if (isNotDefined(path)) return "";

  const value = JSONPath({ path, json: props.item })[0];
  return value ?? props.column.emptyText ?? "";
});

const iconName = computed(() => {
  return props.column.iconName ?? "undefined";
});

const iconColor = computed(() => {
  return props.column.iconColor ?? "#7788aa";
});
</script>

<style lang="scss" scoped>
.squircle-text-cell-container {
  display: flex;
  align-items: center;
  height: 100%;

  font-style: normal;

  font-size: 16px;
  line-height: 20px;
  color: $uds-neutral-900;

  .st-icon-wrapper {
    min-width: 70px;
    max-width: 70px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .st-text-wrapper {
    width: 70%;

    display: flex;
    flex-direction: column;
  }
}
</style>

<template>
  <div class="item-line-container" :class="{ '--loading': isLoading }" @click="addItemToQuery">
    <!-- <div class="item-line-selected-line" :class="{ '--primary-background': itemSelected }" /> -->
    <div
      class="item-line-cells"
      :class="{
        '--darker-background': itemSelected,
        '--padding': paddingLine,
        '--odd': !isEvenLine,
        '--error-background': itemErrored,
      }"
    >
      <template v-for="(column, idColumn) in config">
        <template v-if="column.shouldDisplay">
          <slot :key="idColumn" name="cells" :column="column" />

          <CommonsTablesItemLineDateCell
            v-if="column.type === EColumnType.DATE"
            :key="`date_${idColumn}`"
            :item="item"
            :column="column"
            :style="`width: ${column.width}px;`"
          />
          <CommonsTablesItemLineDateTextCell
            v-else-if="column.type === EColumnType.DATE_TEXT"
            :key="`datext_${idColumn}`"
            :item="item"
            :column="column"
            :style="`width: ${column.width}px;`"
          />
          <CommonsTablesItemLineSquircleTextCell
            v-else-if="column.type === EColumnType.SQUIRCLE_TEXT"
            :key="`squircle_${idColumn}`"
            :item="item"
            :column="column"
            :style="`width: ${column.width}px;`"
          />
          <CommonsTablesItemLineVatCell
            v-else-if="column.type === EColumnType.VAT"
            :key="`vat_${idColumn}`"
            :item="item"
            :column="column"
            :style="`width: ${column.width}px;`"
          />
          <CommonsTablesItemLineVatAndAmountCell
            v-else-if="column.type === EColumnType.VAT_AMOUNT"
            :key="`vatAmount_${idColumn}`"
            :item="item"
            :column="column"
            :style="`width: ${column.width}px;`"
          />
          <CommonsTablesItemLineAmountCell
            v-else-if="column.type === EColumnType.AMOUNT"
            :key="`amount_${idColumn}`"
            :item="item"
            :column="column"
            :style="`width: ${column.width}px;`"
          />
          <CommonsTablesItemLineTextCell
            v-else-if="column.type === EColumnType.TEXT"
            :key="`text_${idColumn}`"
            :item="item"
            :column="column"
            :style="`flex: 0 0 ${column.width}px;`"
          />
        </template>
      </template>
    </div>
    <div
      class="item-line-selected-line"
      :class="{ '--primary-background': itemSelected, '--error-background': itemErrored }"
    />
  </div>
</template>

<script setup lang="ts">
import type { ConfigColumn } from "~/@types/localTypes/table";
import { EColumnType } from "~/@types/localTypes/table";

const props = withDefaults(
  defineProps<{
    item: any;
    isLoading: boolean;
    itemSelected: boolean;
    paddingLine?: boolean;
    config: ConfigColumn[];
    isEvenLine?: boolean;
    itemErrored?: boolean;
  }>(),
  {
    isEvenLine: true,
  },
);
const emit = defineEmits(["addItemToQuery"]);

const isLoading = computed(() => {
  return props.item.isLoading ?? false;
});

function addItemToQuery(event: MouseEvent) {
  // don't open sidepanel if click on checkbox
  if (
    // @ts-expect-error
    event?.target?.parentElement?.parentElement?.className.includes("uds-checkbox-container") ||
    // @ts-expect-error
    event?.target?.parentElement?.parentElement?.parentElement?.className.includes(
      "uds-checkbox-container",
    )
  ) {
    return;
  }
  emit("addItemToQuery");
}
</script>

<style lang="scss">
.item-line-container {
  display: flex;
  height: 68px;

  border-top: 1px solid $uds-neutral-500;
  border-right: 1px solid $uds-neutral-500;
  border-left: 1px solid $uds-neutral-500;

  overflow: hidden;

  &:hover {
    cursor: pointer;
    background-color: $uds-neutral-50;
    .item-line-selected-line,
    .item-line-cells:not(.--header) {
      background-color: $uds-neutral-50;
    }
  }

  // opacity: 1;
  // transition: opacity 0.2s ease-in;

  &.--loading {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &.--disabled {
    .item-line-cells {
      background-color: $uds-neutral-50;
    }
  }

  .item-line-selected-line {
    height: 100%;
    width: 2px;
    background-color: $uds-white;

    &.--primary-background {
      background-color: $uds-primary-500;
    }
    &.--error-background {
      background-color: $uds-error-500 !important;
    }
  }

  .item-line-cells {
    flex-grow: 1;
    overflow-y: hidden;

    height: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;

    background-color: $uds-white;

    &.--padding {
      padding: 0 $uds-spacing-3;
    }

    &.--darker-background {
      background-color: $uds-primary-50 !important;
    }

    &.--error-background {
      background-color: $uds-error-50 !important;
    }

    .header-cell {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
      font-size: 16px;
      line-height: 20px;
      color: $uds-neutral-700;
      &.--center {
        justify-content: center;
      }
    }
    &.--odd {
      background-color: $uds-neutral-50;
    }
  }
}
.table-group :nth-child(1 of .item-line-container) {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.table-group {
  .item-line-container:last-child {
    border-bottom: 1px solid $uds-neutral-500;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
</style>

<template>
  <div class="vat-and-amount-cell-container">
    <div class="vaac-line">
      <uds-amount-currencie
        :value="amountTTC"
        :has-symbol="hasSymbol(amountTTC)"
        class="ttc-amount"
      />
      <template v-if="!isNoTva">ttc</template>
    </div>
    <div v-if="!isNoTva" class="vaac-line --ht">
      <uds-amount-currencie :value="amountHT" :has-symbol="hasSymbol(amountHT)" class="ht-amount" />
      ht
    </div>
  </div>
</template>

<script setup lang="ts">
import { JSONPath } from "jsonpath-plus";
import type { ConfigColumn } from "~/@types/localTypes/table";
import { devideBy100, roundAtXDecimals } from "~/utils/amounts";

const societyStore = useSocietyStore();

const props = defineProps<{
  item: any;
  column: ConfigColumn;
}>();

const amountHT = computed(() => {
  const path = props.column.path;
  if (isNotDefined(path)) return 0;

  const value = props.item[path] ?? 0;

  return devideBy100(value) as number;
});

const vat = computed(() => {
  const path = props.column.data;
  if (isNotDefined(path)) return { value: 0 };

  return JSONPath({ path, json: props.item })[0] ?? { value: 0 };
});

const amountTTC = computed(() => {
  const price = roundAtXDecimals(amountHT.value, 2);
  const percentageVatValue = vat.value.value ?? 0;

  const vatAmount = price * (percentageVatValue / 100);
  return price + vatAmount;
});

const isNoTva = computed(() => societyStore.isNotSubjectToVat);

function hasSymbol(amount: number | undefined): boolean {
  return (amount ?? 0) < 0;
}
</script>

<style lang="scss">
.vat-and-amount-cell-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: 100%;

  padding-right: $uds-spacing-2;
  .vaac-line {
    display: flex;
    gap: $uds-spacing-1;
    font-size: 18px;
    align-items: center;
    .ttc-amount {
      font-weight: $uds-weight-500;
      font-size: 18px;
      color: $uds-neutral-900;
    }
    &.--ht {
      color: $uds-neutral-800;
    }
    .ht-amount {
      color: $uds-neutral-800;
    }
  }
}
</style>
